import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/header/header'
import RegHero from '../components/regHero/regHero'
import Footer from '../components/footer/footer'
import Steps from '../components/steps/steps'
import Partners from '../components/partnersBlock/partnersBlock'
import Testimonials from '../components/testimonials/testimonials'
import TextAccordion from '../components/textAccordion/textAccordion'
import Plan from '../components/plan/plan'
import InsurerSlider from '../components/insurerSlider/insurerSlider'
import SingleText from '../components/singleText/singleText'
import TwoCol from '../components/twoColumn/twoColumn'
import TwoColBlock from '../components/twoColumnBlock/twoColumnBlock'
import Blockquote from '../components/blockQuote/blockQuote'
import Classic from '../components/classic/classic'
import FormBlock from '../components/formBlock/formBlock'
import TextImage from '../components/textImage/textImage'
import MapBlock from '../components/mapBlock/mapBlock'
import CtaBlock from '../components/ctaBlock/ctaBlock'
import GenericText from '../components/genericText/genericText'
import Banner from '../components/bannerBlock/bannerBlock'
import InsuranceTable from '../components/insuranceTable/insuranceTable'
import AccordionBlock from '../components/accordionBlock/accordionBlock'
import {Helmet} from "react-helmet";

const WpPost = ({data}) => {
	const images = data.allWpMediaItem.edges;
	const pageData = JSON.parse(data.wpPage.blocksJSON);

	const LoadSection = ({ val, imageArray }) => {
		let name = (val.attributes.name) ? val.attributes.name : (val.name) ? val.name : undefined
		switch (name) {
			case "acf/steps" :
			return <Steps section={val} images={imageArray} />;
			case "acf/partners" :
			return <Partners section={val} images={imageArray} />;
			case "acf/testimonials" :
			return <Testimonials section={val} images={imageArray} ratings={data.wp.themeGeneralSettings.ratingsWidget} />;
			case "acf/plan" :
			return <Plan section={val} images={imageArray} />;
			case "acf/text-accordion" :
			return <TextAccordion section={val} images={imageArray} />;
			case "acf/insurers-slider" :
			return <InsurerSlider section={val} images={imageArray} />;
			case "acf/single-column-text-block" :
			return <SingleText section={val} images={imageArray} />;
			case "acf/two-column-content" :
			return <TwoCol section={val} images={imageArray} />;
			case "acf/blockquote-block" :
			return <Blockquote section={val} images={imageArray} />;
			case "core/freeform" :
			return <Classic section={val} images={imageArray} />;
			case "acf/form-block" :
			return <FormBlock section={val} images={imageArray} />;
			case "acf/text-image-block" :
			return <TextImage section={val} images={imageArray} />;
			case "acf/map-block" :
			return <MapBlock section={val} images={imageArray} />;
			case "acf/accordions-block" :
			return <AccordionBlock section={val} images={imageArray} />;
			case "acf/cta-block" :
			return <CtaBlock section={val} images={imageArray} />;
			case "acf/generic-text-block" :
			return <GenericText section={val} images={imageArray} />;
			case "acf/table-block" :
			return <InsuranceTable section={val} images={imageArray} />;
			case "acf/two-column-block" :
			return <TwoColBlock section={val} images={imageArray} />;
			case "acf/banner-block" :
			return <Banner section={val} images={imageArray} />;
			default:
			return "Block (" + name + ") not found. ";
		}
	};

  return (
	<div>
		<Helmet>
			<script id="petted-quote-engine" src="https://petinsurer.azurewebsites.net/Scripts/lib/widgets/petted/vertical/quote-form/widget.min.js" type="text/javascript"></script>

			<script>{`
				window.addEventListener('load', function () {
					QuoteEnginePetted.setOptions({
						targetId: "petted-quote-form",
						redirectUrl: "https://petinsurer.azurewebsites.net/quote",
						baseUrl: "https://petinsurer.azurewebsites.net/",
						urlParam: { source: "PettedWidgetSingle", utm_source: "", utm_medium: "", utm_campaign: "", utm_content: "", utm_term: ""},
						refCode: "co",
					});
					QuoteEnginePetted.init();
				});
			`}</script>
		</Helmet>
		<Layout data={data.wpPage.seo}></Layout>
		<RegHero section={data.wpPage} title='Submit a Review' current={data.wpPage.uri} breadcrumbs={data.wpPage.seo.breadcrumbs} ></RegHero>
		<main className="main blog" role="main" id="main-content">
			<article id="post-<?php the_ID(); ?>">
				<div class="post__wrapper insurer__content container">
					<div class="col col-lg-2-3 submitWrap"> 
						{
							pageData.map((section, i) => (
								<LoadSection val={section} key={i} imageArray={images} />
							))
						}
						<iframe src="https://dev-petted2.pantheonsite.io/submit-form-iframe/" title="W3Schools Free Online Web Tutorials"></iframe>
						<p class="small">By leaving a review, I have read the <a href="https://www.petinsurer.com/aff/petsense/terms-conditions/" target="_blank" rel="noopener noreferrer">terms</a> and <a href="https://www.petinsurer.com/aff/petsense/privacy-policy/" target="_blank" rel="noopener noreferrer">privacy policy</a>.</p>
					</div>
					<div class="col col-lg-1-3">
						<div id="petted-quote-form"></div>
					</div>
				</div>
			</article>
		</main>
        <Footer data={data.wp.acfOptionsFooter.footerOptions}></Footer>
    </div>
  )
}

export const query = graphql`
    query ($id: String) {
		wpPage(id: { eq: $id }) {
			blocksJSON
			featuredHero {
				enableFeaturedHero
				fieldGroupName
				heroVideoId
				introduction
				pageTitle
				showBreadcrumbs
				heroImage {
					sourceUrl
					altText
				}
				heroImageMobile {
					sourceUrl
				}
			}
			seo {
				breadcrumbs {
					text
					url
				}
				fullHead
				schema {
					raw
				}
			}
			hero {
				heroContent
				heroButtonText
				heroButtonLink {
					  url
				}
				heroImage {
					  sourceUrl
					  altText
				}
				heroMobileImage {
					  sourceUrl
				}
			}
			noneHomeHeader {
				nhhMoveImageDown
				nhhBackgroundImage {
					sourceUrl
				}
				nhhBackgroundImageMobile {
					sourceUrl
				}
				nhhImage {
					sourceUrl
					altText
				}
			}
			pageSettings {
				altHeaderWave
				noContainerOnMain
			}
		}

		wp {
			acfOptionsFooter {
				footerOptions {
					copyrightMessage
					facebook
					fieldGroupName
					footerLogo {
						sourceUrl
					}
					instagram
					tagline
					tiktok
				}
			}
		}
		allWpMediaItem {
			edges {
				node {
					id
					sourceUrl
					databaseId
				}
			}
	  	}
	}
`

export default WpPost